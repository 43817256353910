import React from 'react';
import { Card, Text, Group, Spoiler, Tabs, Container } from '@mantine/core';
import { db } from '../../config/firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import RelatedArticles from '../RelatedArticles';

import './articlePage.css';

const Anchorpage = ({ beforelink, afterlink }) => {
  return (
    <div style={{ textAlign: 'center', marginTop: '1em' }}>
      <Group justify="center" gap="md">
        {beforelink && (
          <a
            href={`/article/${beforelink}`}
            style={{
              textDecoration: 'none',
              color: '#555',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5em',
              transition: 'color 0.2s ease-in-out',
            }}
            className="nav-link"
            onMouseOver={(e) => (e.currentTarget.style.color = '#003366')}
            onMouseOut={(e) => (e.currentTarget.style.color = '#555')}
          >
            <IconArrowLeft size={18} /> Article précédent
          </a>
        )}
        {afterlink && (
          <a
            href={`/article/${afterlink}`}
            style={{
              textDecoration: 'none',
              color: '#555',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5em',
              transition: 'color 0.2s ease-in-out',
            }}
            className="nav-link"
            onMouseOver={(e) => (e.currentTarget.style.color = '#003366')}
            onMouseOut={(e) => (e.currentTarget.style.color = '#555')}
          >
            Article suivant <IconArrowRight size={18} />
          </a>
        )}
      </Group>
    </div>
  );
};

const ArticleCard = ({ userId, onDataLoad }) => {
  const [articleData, setArticleData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'law_text', userId);
        const user = await getDoc(docRef);
        if (user.exists()) {
          const data = user.data();
          setArticleData(data);

          // Transmettez les données au parent
          if (onDataLoad) {
            onDataLoad(data);
          }
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du document :', error);
      }
    };

    fetchData();
  }, [userId, onDataLoad]);

  return (
    <>
      {articleData ? (
        <Container size="md" style={{ marginTop: '2em' }}>
          <Anchorpage beforelink={articleData.before} afterlink={articleData.after} />
          <Card shadow="md" padding="lg" radius="md" withBorder mt="2em" style={{ background: '#f9f9f9' }}>
            {/* Titre de l'article */}
            <Text fw={700} size="xl" mt="sm" style={{ textAlign: 'center', color: '#003366' }}>
              {articleData.titre}
            </Text>
            {/* Titre du texte cliquable */}
            <Text
              fw={500}
              size="md"
              c="dimmed"
              style={{
                textAlign: 'center',
                marginTop: '0.5em',
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '#555',
                transition: 'color 0.2s ease-in-out',
              }}
              onMouseOver={(e) => (e.currentTarget.style.color = '#003366')}
              onMouseOut={(e) => (e.currentTarget.style.color = '#555')}
              onClick={() => {
                window.location.href = `/laws/${articleData.law_text_collectionId}/${articleData.id_suffix}`;
              }}
            >
              {articleData.law_text}
            </Text>
            {/* Keyword */}
            <Text size="sm" fw={500} mt="md" style={{ textAlign: 'center', color: '#555' }}>
              {articleData.keyword}
            </Text>
            {/* Corpus */}
            <div
              style={{
                marginTop: '1.5em',
                padding: '1em',
                background: '#ffffff',
                borderRadius: '8px',
                textAlign: 'justify',
                lineHeight: '1.6',
                color: '#333',
              }}
              dangerouslySetInnerHTML={{ __html: articleData.corpus }}
            />
            {/* Spoiler */}
            <Spoiler maxHeight={80} showLabel="Voir plus" hideLabel="Cacher" mt="md">
              <Text size="sm" c="dimmed">
                {/* Ajoutez des détails supplémentaires ici si nécessaire */}
              </Text>
            </Spoiler>
          </Card>
        </Container>
      ) : (
        <Container size="sm" style={{ marginTop: '2em', textAlign: 'center' }}>
          <Loader color="blue" size="xl" type="dots" />
        </Container>
      )}
    </>
  );
};

const ArticleContentPage = (props) => {

  const [articleData, setArticleData] = useState(null);
  const handleDataLoad = (data) => {
    setArticleData(data); // Mettez à jour articleData lorsque les données sont chargées
  };
  let orient = null;
  if (window.innerWidth <= 1000) {
    orient = 'horizontal';
  } else {
    orient = 'vertical';
  }

  return (
    <div>
      <Tabs defaultValue="article" orientation={orient} >
        <Tabs.List>
          <Tabs.Tab value="article">Article</Tabs.Tab>
          <Tabs.Tab value="decision">Décisions</Tabs.Tab>
          <Tabs.Tab value="comment">Commentaires</Tabs.Tab>
          <Tabs.Tab value="law">Articles cités ou en lien</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="article">
          {/* Passer handleDataLoad pour recevoir articleData */}
          <ArticleCard {...props} onDataLoad={handleDataLoad} />
        </Tabs.Panel>
        <Tabs.Panel value="decision">
        <Text size="sm" color="dimmed" style={{ textAlign: 'center', marginTop: '20px' }}>
              Aucune décisions disponible pour cet article.
            </Text>

        </Tabs.Panel>
        <Tabs.Panel value="comment">
            <Text size="sm" color="dimmed" style={{ textAlign: 'center', marginTop: '20px' }}>
              Aucun commentaire disponible pour cet article.
            </Text>
        </Tabs.Panel>
        <Tabs.Panel value="law">
          {/* Passer `corpus` au composant RelatedArticles */}
          {articleData?.corpus ? (
            <RelatedArticles corpus={articleData.corpus} title={articleData.titre} law_text={articleData.law_text}/>
          ) : (
            <Text size="sm" color="dimmed" style={{ textAlign: 'center', marginTop: '20px' }}>
              Aucun contenu disponible pour cet article.
            </Text>
          )}
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default ArticleContentPage;