import {
  IconBook,
  IconGavel,
  IconScale,
  IconFileText,
  IconArticle,
  IconFileCertificate,
} from '@tabler/icons-react';
import imageLoi3 from '../image_loi_3.png'; 
import image_loinat1 from '../image_loinat1.png'; 

import imageDecNat1 from '../image_decnat_1.png'; 
import imageDecNat2 from '../image_decnat_2.png'; 

import imageDeced1 from '../image_deced_1.png'; 
import imageDeced2 from '../image_deced_2.png'; 
import imageDecoh1 from '../image_decoh_1.png'; 
import imageDecoh2 from '../image_decoh_2.png'; 
import icon_app from '../icon_app.jpg'
export const legalInformationData = [
  {
    title: 'Codes et lois sénégalais',
    message: 'Consultez les textes bruts, explorez les articles, leurs commentaires et articles en lien.',
    description: 'Accédez à une base complète des codes et lois en vigueur au Sénégal.',
    icon: <IconBook size={40} />,
    images: [image_loinat1],
  },
  {
    title: 'Jurisprudence nationale',
    message: 'Consultez les décisions brutes des tribunaux nationaux, leurs résumés, commentaires, et décisions en lien.',
    description: 'Consultez les décisions rendues par les tribunaux et les cours de justice au Sénégal.',
    icon: <IconGavel size={40} />,
    images: [imageDecNat2, imageDecNat1],
  },
  {
    title: "Droit de l'OHADA",
    message: 'Consultez les actes uniformes en vigueur, explorez les interprétations juridiques et cas pratiques.',
    description: 'Retrouvez les actes uniformes et les textes du droit OHADA appliqués dans toute la zone.',
    icon: <IconScale size={40} />,
    images: [imageLoi3],
  },
  {
    title: 'Justice communautaire CEDEAO',
    message: 'Consultez les décisions brutes des tribunaux nationaux, leurs résumés, commentaires, et décisions en lien.',
    description: 'Accédez aux décisions de la Cour de justice de la CEDEAO.',
    icon: <IconGavel size={40} />,
    images: [imageDeced1, imageDeced2],
  },
  {
    title: 'CCJA (OHADA)',
    message: 'Consultez les décisions de la Cour Commune de Justice et d’Arbitrage et explorez les analyses associées.',
    description: "Retrouvez les décisions de la Cour Commune de Justice et d'Arbitrage de l'OHADA.",
    icon: <IconGavel size={40} />,
    images: [imageDecoh1, imageDecoh2],
  },
  {
    title: 'Conventions fiscales',
    message: 'Explorez les conventions fiscales en vigueur, leurs implications et analyses détaillées.',
    description: 'Explorez les conventions fiscales en vigueur et leur impact sur les entreprises et les particuliers.',
    icon: <IconFileText size={40} />,
    images: [icon_app],
  },
  {
    title: 'Conventions collectives',
    message: 'Consultez les conventions collectives, leurs interprétations et implications dans les relations de travail.',
    description: 'Consultez les conventions collectives qui régissent les relations de travail au Sénégal.',
    icon: <IconFileText size={40} />,
    images: [icon_app],
  },
  {
    title: 'Doctrine',
    description: 'Accédez aux ouvrages, articles, et mélanges doctrinaux disponibles pour approfondir vos connaissances juridiques.',
    icon: <IconArticle size={40} />,
    images: [icon_app],
  },
  {
    title: 'Thèses',
    description: 'Consultez les thèses disponibles en droit pour enrichir vos recherches académiques.',
    icon: <IconFileCertificate size={40} />,
    images: [icon_app],
  },
  {
    title: 'Mémoires',
    description: 'Retrouvez les mémoires rédigés dans le domaine du droit pour approfondir votre compréhension.',
    icon: <IconFileCertificate size={40} />,
    images: [icon_app],
  },
];
