import React from 'react';
import styled from 'styled-components';
import { fonts } from './themes';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  min-height: 100vh;
  background: linear-gradient(135deg, #1e293b, #0f172a); /* Ajout du background cohérent */
  color: #ffffff;
  font-family: ${fonts.body};

  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const Slogan = styled.h2`
  font-family: ${fonts.heading};
  font-size: 2.5rem;
  color: #38bdf8;
  margin-bottom: 1rem;
  text-align: center;
  transition: color 0.3s ease;

  &:hover {
    color: #06b6d4; /* Accentuation au survol */
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Title = styled.h3`
  font-size: 2rem;
  margin-top:15%;
  font-weight: bold;
  color: #cbd5e1;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Content = styled.div`
  max-width: 800px;
  font-size: 1rem;
  line-height: 1.8;
  text-align: justify;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.6;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const AboutPage = () => {
  return (
    <AboutContainer>
      <Title>Pourquoi simplifier l'accès au droit ?</Title>
      <Slogan>« Parce que la justice commence par la connaissance »</Slogan>
      <Content>
        <Paragraph>
          Nous croyons que l'accès au droit est un levier fondamental pour construire une société plus équitable et inclusive. Trop souvent, les textes de loi, les décisions de justice et les réglementations restent inaccessibles à ceux qui en ont le plus besoin.
        </Paragraph>
        <Paragraph>
          C'est pourquoi notre plateforme s'efforce de rendre le droit sénégalais et africain compréhensible et accessible à tous. Grâce à une organisation claire et intuitive des données juridiques, associée à des outils basés sur l'intelligence artificielle, nous facilitons l'accès à des informations fiables et précises en un instant.
        </Paragraph>
        <Paragraph>
          Que vous soyez un professionnel du droit, une entreprise, ou un citoyen curieux, notre objectif est de vous fournir les ressources nécessaires pour comprendre vos droits et obligations. Rejoignez-nous pour découvrir un outil conçu pour transformer l'accès à la justice et à la connaissance juridique en Afrique.
        </Paragraph>
      </Content>
    </AboutContainer>
  );
};

export default AboutPage;
