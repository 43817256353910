import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Text } from "@mantine/core";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../config/firebase-config";

const PasswordResetOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Assurez-vous que cela est au-dessus des autres éléments */
`;

const PasswordResetContainer = styled.div`
  text-align: center;
  padding: 2rem;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
`;

const StyledInput = styled(Input)`
  margin-bottom: 1rem;
`;

const ErrorMessage = styled(Text)`
  color: red;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled(Text)`
  color: green;
  margin-bottom: 1rem;
`;

export const PasswordReset = ({ onBackToLogin }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSendResetEmail = async () => {
    if (!email.trim()) {
      setErrorMsg("Veuillez saisir une adresse e-mail.");
      return;
    }

    const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

    if (!validateEmail(email)) {
      setErrorMsg("Veuillez saisir une adresse e-mail valide.");
      return;
    }

    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email.trim());
      setResetEmailSent(true);
    } catch (error) {
      setErrorMsg(
        "Erreur lors de l'envoi de l'e-mail de réinitialisation de mot de passe."
      );
      setTimeout(() => setErrorMsg(""), 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PasswordResetOverlay>
      <PasswordResetContainer>
        {!resetEmailSent ? (
          <>
            <Text size="lg" weight={600} mb="lg">
              Réinitialiser le mot de passe
            </Text>
            {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
            <StyledInput
              placeholder="Adresse e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              fullWidth
              variant="gradient"
              gradient={{ from: "indigo", to: "cyan" }}
              loading={loading}
              onClick={handleSendResetEmail}
            >
              Envoyer l'e-mail de réinitialisation
            </Button>
          </>
        ) : (
          <SuccessMessage>
            Un e-mail de réinitialisation a été envoyé à {email}. Vérifiez votre
            boîte de réception, y compris les spams.
          </SuccessMessage>
        )}
        <Button variant="subtle" color="blue" mt="md" onClick={onBackToLogin}>
          Retour à la connexion
        </Button>
      </PasswordResetContainer>
    </PasswordResetOverlay>
  );
};
