import React from 'react';
import styled from 'styled-components';
import { IconGavel, IconSchool, IconBook, IconUsers, IconBuilding, IconScale } from '@tabler/icons-react';
import { Container } from '@mantine/core';
import { colors, fonts } from './themes'; // Importing the theme

const AudienceWrapper = styled.div`
  padding: 4rem 1rem;
  background-color: ${colors.white};

  @media (max-width: 768px) {
    padding: 2rem 1rem; /* Reduce padding for mobile devices */
  }
`;

const AudienceContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: center;
  }
`;

const Title = styled.h3`
  text-align: center;
  color: ${colors.text};
  font-family: ${fonts.heading};
  font-size: 1.8rem; /* Adjusted font size */
  margin-bottom: 2.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.blue.dark};
  }

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Further reduce font size for mobile */
    margin-bottom: 2rem; /* Adjust margin for mobile */
  }
`;

const AudienceItem = styled.div`
  flex: 1 1 30%;
  max-width: 250px;
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    max-width: 100%; /* Make items full width on mobile */
    margin-bottom: 1.5rem; /* Adjust margin for mobile */
  }
`;

const AudienceIcon = styled.div`
  margin-bottom: 1rem;
  color: ${colors.blue.superDark};
  font-size: 2rem;
  transition: color 0.3s ease;

  ${AudienceItem}:hover & {
    color: ${colors.blue.dark};
  }

  @media (max-width: 768px) {
    font-size: 1.75rem; /* Reduce icon size for mobile */
  }
`;

const AudienceText = styled.p`
  color: ${colors.text};
  font-family: ${fonts.body};
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;

  ${AudienceItem}:hover & {
    color: ${colors.blue.dark};
  }

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for mobile */
  }
`;

const AudienceDescription = styled.p`
  color: #4a5568;
  font-family: ${fonts.body};
  font-size: 0.875rem;
  transition: color 0.3s ease;

  ${AudienceItem}:hover & {
    color: ${colors.text};
  }

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Adjust font size for mobile */
  }
`;

export function TargetAudience() {
  return (
    <AudienceWrapper>
      <Title>Des outils juridiques adaptés à chaque profession</Title>
      <AudienceContainer>
        <AudienceItem>
          <AudienceIcon>
            <IconScale size={40} />
          </AudienceIcon>
          <AudienceText>Avocats</AudienceText>
          <AudienceDescription>
            Gagnez du temps avec une recherche juridique rapide et efficace, tout en accédant à une base de données complète pour vos dossiers.
          </AudienceDescription>
        </AudienceItem>
        <AudienceItem>
          <AudienceIcon>
            <IconGavel size={40} />
          </AudienceIcon>
          <AudienceText>Magistrats</AudienceText>
          <AudienceDescription>
            Accédez facilement aux jurisprudences les plus récentes et améliorez votre prise de décision avec des analyses juridiques précises.
          </AudienceDescription>
        </AudienceItem>
        <AudienceItem>
          <AudienceIcon>
            <IconSchool size={40} />
          </AudienceIcon>
          <AudienceText>Enseignants Chercheurs</AudienceText>
          <AudienceDescription>
            Enrichissez vos recherches académiques avec des ressources juridiques à jour et développez des enseignements de pointe.
          </AudienceDescription>
        </AudienceItem>
        <AudienceItem>
          <AudienceIcon>
            <IconBook size={40} />
          </AudienceIcon>
          <AudienceText>Étudiants</AudienceText>
          <AudienceDescription>
            Préparez vos examens et travaux avec les meilleurs outils d'apprentissage et une base de données juridique exhaustive.
          </AudienceDescription>
        </AudienceItem>
        <AudienceItem>
          <AudienceIcon>
            <IconUsers size={40} />
          </AudienceIcon>
          <AudienceText>Autres professionnels</AudienceText>
          <AudienceDescription>
            Optimisez vos analyses juridiques, fiscales, et comptables avec des informations précises et des outils performants.
          </AudienceDescription>
        </AudienceItem>
        <AudienceItem>
          <AudienceIcon>
            <IconBuilding size={40} />
          </AudienceIcon>
          <AudienceText>Citoyens</AudienceText>
          <AudienceDescription>
            Comprenez vos droits et obligations grâce à des ressources juridiques claires et accessibles.
          </AudienceDescription>
        </AudienceItem>
      </AudienceContainer>
    </AudienceWrapper>
  );
}

export default TargetAudience;
