// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import {getAuth,GoogleAuthProvider,FacebookAuthProvider} from "firebase/auth"
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDdkSLG5jOv82UKQvvj4YIw441yiMDCFBw",
  authDomain: "legisn-88c78.firebaseapp.com",
  projectId: "legisn-88c78",
  storageBucket: "legisn-88c78.appspot.com",
  messagingSenderId: "636896720256",
  appId: "1:636896720256:web:f83ce0e59be2793e1cfd65",
  measurementId: "G-NYGJ94YPV5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const auth =  getAuth(app)
export const provider = new GoogleAuthProvider();
export const fbprovider = new FacebookAuthProvider();
provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
provider.addScope('https://www.googleapis.com/auth/userinfo.email');
fbprovider.addScope('email');


export const db = getFirestore(app)
