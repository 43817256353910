import React from 'react';
import styled from 'styled-components';
import { Button, Title, Text, ThemeIcon } from '@mantine/core';
import { IconBriefcase, IconScale, IconUserCheck } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import imageHome from '../assets/image_home.png';

const FullWidthBackground = styled.div`
  margin-top:2%;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  padding: 3rem 1rem;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 2rem 1rem; /* Réduit le padding sur mobile */
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;

  @media (max-width: 768px) {
    transform: none; /* Supprime le décalage sur mobile */
    width: 100%; /* Ajuste pour prendre toute la largeur */
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 15%;
  left: 50%;
  transform: translate(15%, 15%);
  z-index: 1;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 120%;
    opacity: 0.4;
  }

  @media (max-width: 768px) {
    left: 0;
    transform: none;
    bottom: 0;
    img {
      max-height: 50%; /* Limite la hauteur sur mobile */
      opacity: 0.3; /* Réduit l'opacité pour moins de distraction */
    }
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  p {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #e2e8f0;
  }

  @media (max-width: 768px) {
    gap: 0.5rem;
    p {
      font-size: 1rem; /* Réduit la taille de police sur mobile */
      text-align: left; /* Aligne le texte à gauche sur mobile */
    }
  }
`;

const StyledTitle = styled(Title)`
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;
  letter-spacing: 1px;
  color: #38bdf8;

  @media (max-width: 768px) {
    font-size: 1.8rem; /* Réduit la taille de police sur mobile */
  }
`;

const StyledSubtitle = styled(Text)`
  font-size: 1.25rem;
  line-height: 1.8;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #cbd5e1;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem; /* Réduit la taille de police sur mobile */
    line-height: 1.5;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(90deg, #4f46e5, #06b6d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 1rem; /* Réduit légèrement la taille sur mobile */
  }
`;

const CenteredButtonWrapper = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 2rem;

  @media (max-width: 768px) {
    margin-top: 1.5rem; /* Réduit l'espacement sur mobile */
  }
`;

export function Presentation() {
  const navigate = useNavigate();

  return (
    <FullWidthBackground>
      <ImageWrapper>
        <img src={imageHome} alt="Aperçu de la solution juridique" />
      </ImageWrapper>

      <ContentWrapper>
        <StyledTitle>
          Une solution pour gérer vos besoins juridiques efficacement
        </StyledTitle>
        <StyledSubtitle>
          Nous combinons <GradientText>Technologie</GradientText> avancée et <GradientText>Expertise</GradientText> humaine pour vous offrir des outils qui facilitent vos prises de décisions.
        </StyledSubtitle>

        <div>
          <IconTextWrapper>
            <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }}>
              <IconScale size={24} />
            </ThemeIcon>
            <p>Analyses précises validées par des experts.</p>
          </IconTextWrapper>

          <IconTextWrapper>
            <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'teal', to: 'lime' }}>
              <IconUserCheck size={24} />
            </ThemeIcon>
            <p>Accompagnement personnalisé et adapté.</p>
          </IconTextWrapper>

          <IconTextWrapper>
            <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'orange', to: 'red' }}>
              <IconBriefcase size={24} />
            </ThemeIcon>
            <p>Outils pour simplifier vos démarches juridiques.</p>
          </IconTextWrapper>
        </div>

        <CenteredButtonWrapper>
          <Button
            variant="gradient"
            gradient={{ from: 'indigo', to: 'cyan' }}
            size="lg"
            onClick={() => navigate('/register')}
          >
            Essayez gratuitement
          </Button>
        </CenteredButtonWrapper>
      </ContentWrapper>
    </FullWidthBackground>
  );
}

export default Presentation;
