// pages/history/index.jsx
import React from 'react';
import { AppShell, Burger, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import MyNavbar from '../../component/navbar/Navbar';
import { NavbarNested } from '../../component/verticalNavbar/NavbarNested';
import SearchAiHistory from '../../component/SearchAiHistory/SearchAiHistory';

export function History ()  {
  const [opened, { toggle }] = useDisclosure();
  const searchInputRef = React.useRef('');

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header>
        <Group h="100%" w="100%">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <MyNavbar searchInputRef={searchInputRef} />
        </Group>
      </AppShell.Header>

      <AppShell.Navbar p="md" style={{ backgroundColor: '#F5F5F5' }}>
        <NavbarNested />
      </AppShell.Navbar>

      <AppShell.Main>
        <h2>Historique des Recherches</h2>
        <SearchAiHistory />
      </AppShell.Main>
    </AppShell>
  );
};

export default History;
