import React from 'react';
import styled from 'styled-components';
import PortailLayout from '../component/Layout/PortailLayout';

const TermsContainer = styled.div`
  margin-top: 5%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem 2rem;
  min-height: 100vh;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: #38bdf8;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  background: rgba(30, 41, 59, 0.85); /* Fond semi-transparent */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const TableOfContents = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-right: 1.5rem;
  width: 250px;

  li {
    margin-bottom: 0.8rem;
    cursor: pointer;
    color: #38bdf8;
    transition: color 0.3s ease;

    &:hover {
      text-decoration: underline;
      color: #06b6d4;
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 2rem;
  }
`;

const Separator = styled.div`
  border-left: 1px solid #06b6d4;
  height: auto;
  margin: 0 1rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ScrollableContent = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;

  @media (max-width: 1024px) {
    max-height: none;
  }
`;

const SectionTitle = styled.h4`
  font-size: 1.4rem;
  font-weight: bold;
  color: #06b6d4;
  margin-top: 2rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const sections = [
  {
    title: 'Signataire',
    content:
      'La plateforme est exploitée par Ndongo Benoit Ndiaye, fournissant des services d’accès à l’information juridique pour le droit sénégalais et africain.',
  },
  {
    title: "Navigation et Compte d'essai",
    content:
      "L'accès au contenu est libre. Vous pouvez aussi créer un compte d'essai gratuit pour accéder à des services supplémentaires.",
  },
  {
    title: 'Niveaux de service',
    content:
      'Nous garantissons un service stable, avec des mises à jour régulières pour améliorer les fonctionnalités et l’accès à notre base de données.',
  },
  {
    title: 'Propriété intellectuelle',
    content:
      'Tous les contenus présents sur la plateforme, y compris les textes et les logos, sont protégés par des droits de propriété intellectuelle.',
  },
  {
    title: 'Données personnelles',
    content:
      'Les données collectées sont traitées conformément à la réglementation sur la protection des données personnelles, avec une sécurité rigoureuse.',
  },
  {
    title: 'Responsabilité',
    content:
      'Nous déclinons toute responsabilité quant aux pertes directes ou indirectes résultant de l’utilisation de notre plateforme.',
  },
  {
    title: 'Comportements prohibés',
    content:
      'Tout accès non autorisé ou détournement des ressources de la plateforme est strictement interdit et passible de sanctions.',
  },
  {
    title: 'Autres mentions',
    content:
      'Les informations disponibles sont issues de sources officielles et fiables. Nous nous engageons à maintenir l’intégrité de ces données.',
  },
  {
    title: 'Mises à jour',
    content:
      'Les présentes CGU peuvent être mises à jour pour refléter les changements légaux ou technologiques. Nous vous informerons de ces changements par tout moyen adéquat.',
  },
  {
    title: 'Règlement des litiges',
    content:
      'En cas de litige, nous privilégions une résolution à l’amiable avant toute action judiciaire. Le tribunal compétent sera déterminé conformément à la législation locale.',
  },
  {
    title: 'Entrée en vigueur',
    content:
      'Ces conditions générales d’utilisation entrent en vigueur dès leur publication et restent applicables jusqu’à leur modification.',
  },
];

const TermsPage = () => {
  const scrollToSection = (index) => {
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <PortailLayout>
      <TermsContainer>
        <TitleContainer>
          <Title>Conditions Générales d'Utilisation</Title>
        </TitleContainer>

        <ContentWrapper>
          <TableOfContents>
            {sections.map((section, index) => (
              <li key={index} onClick={() => scrollToSection(index)}>
                {index + 1}. {section.title}
              </li>
            ))}
          </TableOfContents>

          <Separator />

          <ScrollableContent>
            {sections.map((section, index) => (
              <div key={index} id={`section-${index}`}>
                <SectionTitle>
                  {index + 1}. {section.title}
                </SectionTitle>
                <Paragraph>{section.content}</Paragraph>
              </div>
            ))}
          </ScrollableContent>
        </ContentWrapper>
      </TermsContainer>
    </PortailLayout>
  );
};

export default TermsPage;
