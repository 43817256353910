import '@mantine/core/styles.css';
import ResponsiveSearchBox from '../search/Search'; 
import cx from 'clsx';
import { useState } from 'react';
import styled from 'styled-components';
import {
  Grid,
  Avatar,
  UnstyledButton,
  Group,
  Menu,
  rem,
  useMantineTheme,
} from '@mantine/core';
import {
  IconLogout,
  IconStar,
  IconMessage,
  IconSettings,
  IconNews,
  IconFileSearch
} from '@tabler/icons-react';
import logo from '../../assets/icon_app.jpg';
import classes from './HeaderTabs.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";

const StyledLogo = styled.img`
  width: 45px;
  height: 45px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 10;
  position: relative;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  /* Masquer le logo sur les petits écrans */
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  &:focus {
    outline: none;
  }
`;

const StyledNavbar = styled(Group)`
  height: 100%;
  align-items: center; /* Center vertically */
`;

const StyledSearch = styled.div`
  margin-bottom: 15px;
  margin: 10px 0;
`;

const StyledProfile = styled.div`
  margin-right: 20px;

  /* Ajuster les marges et la taille de l'avatar sur mobile */
  @media (max-width: 768px) {
    margin-right: 5px;
  }
`;

export function MyNavbar({ searchInputRef }) {
  const theme = useMantineTheme();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const LogOut = () => {
    const isAuth = JSON.parse(localStorage.getItem("auth")) || {};

    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });

    if (isAuth) {
      localStorage.removeItem('auth');
      return navigate("/Login");
    }
  };

  return (
    <StyledNavbar className={classes.mainSection} justify="space-between" style={{ flex: 1 }}>
      <Grid align="center"> {/* Center elements vertically */}
        <Grid.Col span={1}>
          <StyledButton onClick={() => navigate('/Home')}>
            <StyledLogo src={logo} alt="Icône de l'application" />
          </StyledButton>
        </Grid.Col>
        <Grid.Col span={9} md={10}>
          {location.pathname === '/search' && (
            <StyledSearch>
              <ResponsiveSearchBox searchInputRef={searchInputRef} />
            </StyledSearch>
          )}
        </Grid.Col>
        <Grid.Col span={2} md={1}>
          <StyledProfile>
            <Menu
              width={260}
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                >
                  <Group gap={4}>
                    <Avatar radius="xl" size={28} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                 onClick={() => navigate("/history")}
                  leftSection={
                    <IconFileSearch
                      style={{ width: rem(16), height: rem(16) }}
                      color={theme.colors.red[6]}
                      stroke={1.5}
                    />
                  }
                >
                  Historique
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconNews
                      style={{ width: rem(16), height: rem(16) }}
                      color={theme.colors.red[6]}
                      stroke={1.5}
                    />
                  }
                >
                  Actualités
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconStar
                      style={{ width: rem(16), height: rem(16) }}
                      color={theme.colors.yellow[6]}
                      stroke={1.5}
                    />
                  }
                >
                  Favoris
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconMessage
                      style={{ width: rem(16), height: rem(16) }}
                      color={theme.colors.blue[6]}
                      stroke={1.5}
                    />
                  }
                >
                  Mes dossiers
                </Menu.Item>
                <Menu.Divider />
                <Menu.Label>Settings</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconSettings style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                  }
                >
                  Paramètres
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconLogout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                  }
                  onClick={LogOut}
                >
                  Déconnexion
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </StyledProfile>
        </Grid.Col>
      </Grid>
    </StyledNavbar>
  );
}

export default MyNavbar;
