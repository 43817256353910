import React from "react";
import { Navigate } from "react-router-dom";

export const RedirectPage = () => {
  // Récupération des données depuis localStorage
  const authInfo = JSON.parse(localStorage.getItem("auth"));

  // Vérification des informations locales
  const isAuth = authInfo?.isAuth || false;
  const emailVerified = authInfo?.emailVerified;

  // Logique de redirection
  if (isAuth) {
    if (emailVerified === true) {
      return <Navigate to="/Home" />;
    } else if (emailVerified === false) {
      return <Navigate to="/email-verification" />;
    }
  }

  // Redirection par défaut si non authentifié ou emailVerified est `null`/`undefined`
  return <Navigate to="/Portail" />;
};
