import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { FaSearch, FaCalendarAlt, FaSort } from 'react-icons/fa';

const SearchAiHistory = () => {
  const [historyData, setHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('desc'); // 'desc' pour plus récent, 'asc' pour moins récent
  const [filterDate, setFilterDate] = useState('');
  const itemsPerPage = 5;

  useEffect(() => {
    const searchHistory = [];
    for (const key in localStorage) {
      if (key.startsWith('search_summary_')) {
        const item = JSON.parse(localStorage.getItem(key));
        const searchInput = key.replace('search_summary_', '');
        searchHistory.push({ 
          searchInput, 
          summary: item.summary, 
          date: item.date ? convertDateToISO(item.date) : '' // Standardiser les dates pour tri et filtre
        });
      }
    }
    setHistoryData(searchHistory);
  }, []);

  // Fonction pour convertir la date `jj/mm/aaaa hh:mm:ss` en ISO compatible
  const convertDateToISO = (dateString) => {
    const [day, month, yearAndTime] = dateString.split('/');
    const [year, time] = yearAndTime.split(' ');
    return `${year}-${month}-${day}T${time}`;
  };

  // Trier et filtrer les données en fonction des critères
  useEffect(() => {
    const sortedData = [...historyData].sort((a, b) => {
      if (!a.date || !b.date) return 0;
      const dateA = Date.parse(a.date);
      const dateB = Date.parse(b.date);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

    const filtered = sortedData.filter(entry => {
      const matchesSearch = entry.searchInput.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesDate = filterDate ? entry.date.startsWith(filterDate) : true;
      return matchesSearch && matchesDate;
    });

    setFilteredData(filtered);
  }, [historyData, sortOrder, searchTerm, filterDate]);

  // Pagination
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const displayedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => setCurrentPage(page);

  const openSearchAiPage = (searchInput) => {
    window.open(`/searchai?query=${encodeURIComponent(searchInput)}`, '_blank');
  };

  const handleSortOrderChange = () => {
    setSortOrder(prevOrder => (prevOrder === 'desc' ? 'asc' : 'desc'));
    setCurrentPage(1); // Revenir à la première page pour afficher les résultats triés
  };

  return (
    <div style={containerStyle}>
      {/* Barre de recherche et filtres */}
      <div style={filterContainerStyle}>
        <div style={searchBoxContainerStyle}>
          <FaSearch style={iconStyle} />
          <input 
            type="text" 
            placeholder="Rechercher une question..." 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
            style={searchInputStyle}
          />
        </div>

        <div style={filterButtonStyle}>
          <FaCalendarAlt style={iconStyle} />
          <input 
            type="date" 
            value={filterDate} 
            onChange={(e) => setFilterDate(e.target.value)} 
            style={dateInputStyle}
          />
        </div>

        <button onClick={handleSortOrderChange} style={sortButtonStyle}>
          <FaSort style={iconStyle} />
          {sortOrder === 'desc' ? 'Plus récent' : 'Moins récent'}
        </button>
      </div>

      {/* Liste des résultats */}
      {displayedData.length > 0 ? (
        displayedData.map((entry, index) => (
          <div key={index} style={entryStyle}>
            <div style={headerStyle}>
              <h3 style={questionStyle}>{entry.searchInput}</h3>
              {entry.date && <span style={dateStyle}>{entry.date}</span>}
            </div>
            <div style={summaryStyle}>
              <ReactMarkdown>{entry.summary ? `${entry.summary.slice(0, 100)}...` : 'Aucun résumé disponible.'}</ReactMarkdown>
            </div>
            <button 
              style={buttonStyle} 
              onClick={() => openSearchAiPage(entry.searchInput)}
            >
              Voir les détails
            </button>
          </div>
        ))
      ) : (
        <p>Aucun historique de recherche disponible.</p>
      )}

      {/* Pagination */}
      <div style={paginationStyle}>
        {[...Array(totalPages)].map((_, i) => (
          <button 
            key={i} 
            onClick={() => handlePageChange(i + 1)} 
            style={{
              ...pageButtonStyle,
              backgroundColor: currentPage === i + 1 ? '#4A90E2' : '#ffffff',
              color: currentPage === i + 1 ? '#ffffff' : '#4A90E2'
            }}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

// Styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  padding: '20px',
};

const filterContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '15px',
};

const searchBoxContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  padding: '8px 12px',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  border: '1px solid #ddd',
};

const searchInputStyle = {
  flex: 1,
  border: 'none',
  outline: 'none',
  padding: '8px',
  fontSize: '1em',
};

const dateInputStyle = {
  border: 'none',
  outline: 'none',
  padding: '8px',
  fontSize: '1em',
  backgroundColor: 'transparent',
};

const filterButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  padding: '8px',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  border: '1px solid #ddd',
};

const sortButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  padding: '8px',
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  border: '1px solid #ddd',
  cursor: 'pointer',
};

const iconStyle = {
  fontSize: '1.2em',
  color: '#888',
};

const entryStyle = {
  padding: '15px',
  borderRadius: '8px',
  backgroundColor: '#f9f9f9',
  border: '1px solid #ddd',
  textAlign: 'left',
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const questionStyle = {
  fontSize: '1.1em',
  margin: '0 0 10px',
  color: '#333',
};

const dateStyle = {
  fontSize: '0.8em',
  color: '#888',
};

const summaryStyle = {
  padding: '10px',
  backgroundColor: '#ffffff',
  borderRadius: '5px',
  border: '1px solid #ccc',
  fontSize: '0.9em',
  color: '#666',
  marginBottom: '10px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const buttonStyle = {
  padding: '8px 16px',
  backgroundColor: '#4A90E2',
  color: '#ffffff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '0.9em',
  transition: 'background-color 0.3s',
  textAlign: 'center',
};

const paginationStyle = {
  display: 'flex',
  gap: '5px',
  justifyContent: 'center',
  marginTop: '20px',
};

const pageButtonStyle = {
  border: '1px solid #4A90E2',
  borderRadius: '3px',
  padding: '5px 10px',
  cursor: 'pointer',
};

export default SearchAiHistory;
