import React, { useState } from 'react';
import { Button, Menu, Group, UnstyledButton, Burger, Drawer } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/icon_app.jpg';
import { colors } from '../themes'; // Import your theme colors

// Styled components
const StyledLogo = styled.img`
  width: 45px;
  height: 45px;
  margin: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 10;
  position: relative;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    color: ${colors.blue.main}; // Use the color from the theme
  }
`;

const RightGroup = styled(Group)`
  margin-left: auto;  // Pushes the group to the right end
  @media (max-width: 768px) {
    display: none; // Hide on mobile
  }
`;

const StyledInscriptionButton = styled(Button)`
  margin-right: 10px; // Add margin to the right of the "Inscription" button
  background-color: ${colors.blue.dark}; // Example usage of theme color
  &:hover {
    background-color: ${colors.blue.superDark}; // Darker on hover
  }

  @media (max-width: 768px) {
    margin: 10px 0; // Add vertical spacing on mobile
  }
`;

const StyledMenu = styled(Menu)`
  &:hover > div {
    display: block;
    background-color: ${colors.blue.light}; // Example usage in hover
  }

  @media (max-width: 768px) {
    margin-bottom: 10px; // Add vertical spacing on mobile
  }
`;

const DrawerButton = styled(Button)`
  margin: 10px 0; // Add vertical spacing between buttons inside the drawer
`;

export function PortailNavbar() {
  const navigate = useNavigate();
  const [drawerOpened, setDrawerOpened] = useState(false);

  return (
    <>
      <Group position="apart" style={{ width: '100%' }}>
        {/* Left side: Logo */}
        <StyledLogo src={logo} alt="Icône de l'application" onClick={() => navigate('/portail')} />

        {/* Burger Menu for Mobile */}
        <Burger
          opened={drawerOpened}
          onClick={() => setDrawerOpened((o) => !o)}
          size="sm"
          mr="xl"
          display={{ base: 'block', md: 'none' }}
          style={{ marginLeft: 'auto' }} // Ensure the Burger is aligned to the right
        />

        {/* Right side: Menu items */}
        <RightGroup spacing="md">
          <StyledMenu
            shadow="md"
            width={220}
            position="bottom-start"
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton>
                <Group>
                  Plateforme {/* Renamed from "Nos services" */}
                  <IconChevronDown size={14} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Dernières fonctionnalités</Menu.Label>
              <Menu.Item onClick={() => navigate('/assistant-juridique')}>Assistant Juridique</Menu.Item>
              
              <Menu.Label>Fonctionnalités</Menu.Label>
              <Menu.Item onClick={() => navigate('/recherche-instant')}>Recherche Instantanée</Menu.Item>
              <Menu.Item onClick={() => navigate('/recherche-avancee')}>Recherche Intelligente</Menu.Item>
              <Menu.Item onClick={() => navigate('/jurisprudence')}>Accès à la Jurisprudence</Menu.Item>
              <Menu.Item onClick={() => navigate('/doctrines')}>Analyse Doctrinale</Menu.Item>
              <Menu.Item onClick={() => navigate('/alertes-juridiques')}>Alertes Juridiques</Menu.Item>
              {//<Menu.Item onClick={() => navigate('/base-de-donnees')}>Base de Données Juridiques</Menu.Item>}
              }
              <Menu.Item onClick={() => navigate('/actualites')}>Actualités Juridiques</Menu.Item>
            </Menu.Dropdown>
          </StyledMenu>
          <Button variant="subtle" onClick={() => navigate('/tarifs')}>
            Tarifs
          </Button>
          <Button variant="outline" onClick={() => navigate('/Login')}>
            Connexion
          </Button>
          <StyledInscriptionButton onClick={() => navigate('/register')}>
            Inscription
          </StyledInscriptionButton>
        </RightGroup>
      </Group>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        padding="md"
        size="sm"
        title=""
        position="right"
      >
        <DrawerButton fullWidth variant="subtle" onClick={() => navigate('/tarifs')}>
          Tarifs
        </DrawerButton>
        <DrawerButton fullWidth variant="outline" onClick={() => navigate('/Login')}>
          Connexion
        </DrawerButton>
        <DrawerButton fullWidth onClick={() => navigate('/register')}>
          Inscription
        </DrawerButton>
        <StyledMenu
          shadow="md"
          width={220}
          position="bottom-start"
          withinPortal
          fullWidth
        >
          <Menu.Target>
            <UnstyledButton fullWidth>
              <Group>
                Plateforme {/* Renamed from "Nos services" */}
                <IconChevronDown size={14} />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Dernières fonctionnalités</Menu.Label>
            <Menu.Item onClick={() => navigate('/assistant-juridique')}>Assistant Juridique</Menu.Item>
            
            <Menu.Label>Fonctionnalités</Menu.Label>
            <Menu.Item onClick={() => navigate('/recherche-instant')}>Recherche Instantané</Menu.Item>
            <Menu.Item onClick={() => navigate('/recherche-avancee')}>Recherche Avancée</Menu.Item>
            <Menu.Item onClick={() => navigate('/jurisprudence')}>Accès à la Jurisprudence</Menu.Item>
            <Menu.Item onClick={() => navigate('/doctrines')}>Analyse Doctrinale</Menu.Item>
            <Menu.Item onClick={() => navigate('/alertes-juridiques')}>Alertes Juridiques</Menu.Item>
            <Menu.Item onClick={() => navigate('/base-de-donnees')}>Base de Données Juridiques</Menu.Item>
            <Menu.Item onClick={() => navigate('/actualites')}>Actualités Juridiques</Menu.Item>
          </Menu.Dropdown>
        </StyledMenu>
      </Drawer>
    </>
  );
}

export default PortailNavbar;
