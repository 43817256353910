import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Badge, Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useRefinementList,InstantSearch } from 'react-instantsearch';
import PortailLayout from '../component/Layout/PortailLayout';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';


export const { searchClient } = instantMeiliSearch(
    'https://meilisearch.legi.sn/',
    'MobileDevAppTemp1104',
 
  );

// Styles
const JurisprudenceContainer = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-height: 100vh;
  padding: 3rem 1rem;
  background: linear-gradient(135deg, #f4f4f9, #e4e7ec);
  color: #1e293b;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  color: #1e3a8a;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 0.9rem;
  color: #334155;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const SearchWrapper = styled.div`
  max-width: 600px;
  margin-bottom: 1.5rem;
`;

const ThemeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
`;

const ThemeColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const ThemeItem = styled.div`
  background: rgba(30, 41, 59, 0.85);
  padding: 0.6rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    background: rgba(56, 189, 248, 0.2);
  }

  color: #ffffff;
  text-align: left;
  font-size: 0.8rem;
`;

export function PortalJurisprudencePage() {
  const attribute = 'theme_decision';
 const sortBy = ["theme_decision:asc"] 
 const showMore=true
 const showMoreLimit  = 1200
  const limit = 1000;
  const { items, searchForItems } = useRefinementList({ attribute, limit,sortBy,showMore,showMoreLimit });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 60;

  const navigate = useNavigate();

  const filteredThemes = searchQuery
    ? items.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : items;

  const totalPages = Math.ceil(filteredThemes.length / itemsPerPage);
  const currentItems = filteredThemes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const themesByColumn = Array.from({ length: 3 }, () => []);
  currentItems.forEach((item, index) => {
    themesByColumn[index % 3].push(item);
  });

  return (
    <InstantSearch indexName="law_text" searchClient={searchClient}>
    <PortailLayout>
      <JurisprudenceContainer>
        <Title>Rubriques Jurisprudentielles</Title>
        <Subtitle>
          Explorez les thèmes juridiques et accédez aux décisions associées.
        </Subtitle>

        {/* Barre de recherche */}
        <SearchWrapper>
          <Input
            placeholder="Rechercher une rubrique"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              searchForItems(e.target.value);
            }}
            size="sm"
          />
        </SearchWrapper>

        {/* Grille des rubriques */}
        {currentItems.length > 0 ? (
          <ThemeGrid>
            {themesByColumn.map((column, columnIndex) => (
              <ThemeColumn key={columnIndex}>
                {column.map((item) => (
                  <ThemeItem
                    key={item.label}
                    onClick={() =>
                      navigate(`/decisions/rubrique/theme/${encodeURIComponent(item.label)}`)
                    }
                  >
                    {item.label.toUpperCase()}{' '}
                    <Badge color="blue">{item.count}</Badge>
                  </ThemeItem>
                ))}
              </ThemeColumn>
            ))}
          </ThemeGrid>
        ) : (
          <Subtitle>Aucune rubrique trouvée</Subtitle>
        )}

        {/* Pagination */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <Button
            variant="light"
            size="xs"
            onClick={() => setCurrentPage((prev) => prev - 1)}
            disabled={currentPage === 1}
          >
            Précédent
          </Button>
          <Button
            variant="light"
            size="xs"
            onClick={() => setCurrentPage((prev) => prev + 1)}
            disabled={currentPage === totalPages}
          >
            Suivant
          </Button>
        </div>
      </JurisprudenceContainer>
    </PortailLayout>
    </InstantSearch>
  );
}

export default PortalJurisprudencePage;
