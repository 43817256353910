import React from 'react';
import styled from 'styled-components';
import PortailLayout from '../component/Layout/PortailLayout';

const AvisContainer = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  padding: 3rem 1rem;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  color: #38bdf8;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: #cbd5e1;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PlaceholderWrapper = styled.div`
  max-width: 600px;
  background: rgba(30, 41, 59, 0.85);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
`;

const ComingSoon = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: #06b6d4;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export function PortalAvisPage() {
  return (
    <PortailLayout>
      <AvisContainer>
        <PlaceholderWrapper>
          <Title>Portail Avis</Title>
          <Subtitle>
            Bientôt disponible ! Consultez et partagez des avis juridiques fiables et des recommandations.
          </Subtitle>
          <ComingSoon>Rendez-vous prochainement pour découvrir ce contenu !</ComingSoon>
        </PlaceholderWrapper>
      </AvisContainer>
    </PortailLayout>
  );
}

export default PortalAvisPage;
