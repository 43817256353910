import React, { useState, useEffect } from 'react';
import { Loader, Card,Text } from '@mantine/core';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import CustomInfiniteHits from '../component/articleHits/index';
import { InstantSearch } from 'react-instantsearch';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

export const { searchClient } = instantMeiliSearch(
  'https://meilisearch.legi.sn/',
  'MobileDevAppTemp1104',
  {
    primaryKey: 'id',
    keepZeroFacets: true,
    paginationTotalHits: 30,
  }
);

const OPENAI_API_KEY = "sk-whl7KhEt595GlAm8BV29VorWz7T1Wm_dOhDsSHhfQTT3BlbkFJyCNvtjdkQ5mwbrwlvXfMqRX3nNmm6SEjsAOQZPNmwA"; // Remplacez par votre clé OpenAI

const RelatedArticles = ({ corpus, titre,law_text}) => {
  const [flattenedResults, setFlattenedResults] = useState([]);
  const [summary, setSummary] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      if (!corpus || typeof corpus !== 'string' || corpus.trim() === '') {
        setError('Le corpus est vide ou invalide.');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        const response = await axios.post(
          "https://us-central1-editlaw-68f30.cloudfunctions.net/findNearestDocuments",
          { queryText: corpus },
          { headers: { 'Content-Type': 'application/json' } }
        );

        const results = response.data;

        const flattened = results.map((item) => ({
          ...item.data,
          title: item.data.titre,
          law_type: item.data.law_type,
          law_text: item.data.law_text,
          id: item.id,
          vector_distance: item.vector_distance,
        }));

        setFlattenedResults(flattened);

        // Générer un résumé des articles en lien
        generateSummary(flattened);
      } catch (err) {
        console.error('Erreur lors de la récupération des articles liés:', err);
        setError('Erreur lors de la récupération des articles liés.');
      } finally {
        setIsLoading(false);
      }
    };

    const generateSummary = async (articles) => {
      if (!articles.length) {
        setSummary('Aucun article pertinent trouvé pour générer un résumé.');
        return;
      }

      try {
        setIsSummaryLoading(true);
        //console.log(flattenedResults)
        const relatedContext = articles
          .slice(0, 10)
          .map(
            (article, index) =>
              `Article : ${article.titre}\nType: ${article.law_type}\nTexte: ${article.law_text}\nContenu: ${article.corpus}\n\n`
          )
          .join('');

          const prompt = `
          Nous sommes dans le contexte du droit sénégalais, africain et des affaires.
          
          Voici les informations principales de l'article actuellement affiché :
          - Texte de loi : ${law_text}
          - Titre : ${titre}
          - Contenu : ${corpus}
          
          Voici également un extrait des articles juridiques les plus pertinents trouvés par similarité :
          
          ${relatedContext}
          
          En fonction des informations ci-dessus, rédigez un résumé clair et structuré en français des **3 à 5 points principaux** qui ressortent. Le résumé doit être concis (maximum 150 mots) et écrit dans un langage juridique simple et accessible pour un utilisateur non-expert en droit.
          `;

        const openaiResponse = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: "gpt-4o",
            messages: [
              {
                role: 'system',
                content: 'Vous êtes un assistant juridique spécialisé dans le droit sénégalais, africain et des affaires.',
              },
              {
                role: 'user',
                content: prompt,
              },
            ],
            max_tokens: 3000,
            temperature: 0.7,
          },
          {
            headers: {
              'Authorization': `Bearer ${OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const summaryContent = openaiResponse.data.choices[0].message.content.trim();
        setSummary(summaryContent);
      } catch (err) {
        console.error('Erreur lors de la génération du résumé:', err);
        setSummary('Impossible de générer un résumé des articles pour le moment.');
      } finally {
        setIsSummaryLoading(false);
      }
    };

    fetchRelatedArticles();
  }, [corpus,titre,law_text]);

  useEffect(() => {
    if (!summary) return;

    const sentences = summary.split('. '); // Divisez le texte en phrases
    let currentIndex = 0;

    const interval = setInterval(() => {
      if (currentIndex < sentences.length) {
        setDisplayedText((prev) => prev + sentences[currentIndex] + '. ');
        currentIndex++;
      } else {
        setDisplayedText(summary);
        clearInterval(interval);
      }
    }, 300); // Ajoutez une phrase toutes les 300ms pour un effet réaliste

    return () => clearInterval(interval);
  }, [summary]);

  if (isLoading) {
    return <Loader color="blue" size="xl" style={{ margin: '20px auto', display: 'block' }} />;
  }

  if (error) {
    return (
      <Card shadow="sm" padding="lg" radius="md" withBorder style={{ margin: '20px auto', maxWidth: '600px' }}>
        <Text color="red" size="md">{error}</Text>
      </Card>
    );
  }

  return (
    <InstantSearch indexName="law_text" searchClient={searchClient}>
      <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
        <h3 style={{ marginBottom: '20px', textAlign: 'left' }}>Résumé des articles en lien</h3>
        {isSummaryLoading ? (
          <Loader color="blue" size="lg" style={{ margin: '10px auto', display: 'block' }} />
        ) : (
          <Card shadow="sm" padding="lg" radius="md" withBorder style={{ marginBottom: '20px' }}>
            <div style={{ textAlign: 'left' }}>
              <ReactMarkdown>{displayedText}</ReactMarkdown>
            </div>
          </Card>
        )}

        <h3 style={{ marginBottom: '20px', textAlign: 'left' }}>Articles en lien</h3>
        <CustomInfiniteHits hits={flattenedResults} />
      </div>
    </InstantSearch>
  );
};

export default RelatedArticles;
